import { useEffect, useState } from "react";
import { Loading } from "components/ui/loading";
import { Response } from "api";
import { getClients, getUserRoles, setUserRole, getUser, setUserField } from "api/user";
import { useUpdate } from "utils/hooks";
import { Input } from "components/ui/input";
import { Actions } from "./actions";

import styles from "./index.module.scss";

type Roles = Response<typeof getUserRoles>;
type TUser = Response<typeof getClients>[number];
type DetailledUser = Response<typeof getUser>;
type Field = keyof DetailledUser;

interface Props {
	user: TUser;
	onNeedUpdate: (unselect?: boolean) => void;
}

export function Client (props: Props) {
	const [roles, setRoles] = useState<Roles>([]);
	const [dUser, setDUser] = useState<DetailledUser>();
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();
	useEffect(() => {
		(async () => {
			setLoading(true);
			setRoles(await getUserRoles(props.user.id));
			setDUser(await getUser(props.user.id));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.user.id, onUpdate]);
	if (loading || dUser === undefined)
		return <Loading />;
	return <div>
		<div className={ styles.fields }>
			<h2>Client infos</h2>
			<div>
			{
				([
					"address",
					"country",
					"email",
					"fax",
					"phone",
					"bill",
					"comment",
				] as Array<Field>)
				.map((field, index) => <div
					className={ styles.input }
					key={ index }
				>
					<b>{ field[0].toUpperCase() + field.slice(1) }:</b>
					<Input
						default={ dUser[field] }
						placeholder={ `Revendor ${ field }` }
						save={ async value => {
							await setUserField(props.user.id, field, value);
						} }
					/>
				</div>)
			}
			</div>
		</div>
		<div className={ styles.container }>
			<h2>Role</h2>
			<div>
			{
				[...roles].sort((a, b) => a.id - b.id).map((role, i) => <div
					key={ i }
					className={ styles.role }
				>
					<input
					type="checkbox"
					defaultChecked={ role.selected }
					onChange={ _ => {
						(async () => {
							setLoading(true);
							await setUserRole(props.user.id, role.id);
							update();
						})()
						.catch(console.error);
					} } />
					<div className={ styles.info }>
						<div className={ styles.title }>
							{ role.name }
							{ role.default ? " (default)" : "" }
						</div>
						<div className={ styles.description }>{ role.description }</div>
					</div>
				</div>)
			}
			</div>
		</div>
		<Actions
			userId={ props.user.id }
			onNeedUpdate={ () => props.onNeedUpdate(true) }
		/>
	</div>;
}
