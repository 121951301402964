import { Api, Method, Response } from "api";

const api = new Api("api/user");

export const getClients = async function (vendorId?: number) {
	interface Client {
		id: number;
		username: string;
	}
	if (vendorId === undefined)
		return await api.call("/clients") as Array<Client>;
	return await api.call(`/${ vendorId }/clients`) as Array<Client>;
}

export const getNonClients = async function (vendorId?: number) {
	interface User {
		id: number;
		username: string;
	}
	if (vendorId === undefined)
		return await api.call("/non-clients") as Array<User>;
	return await api.call(`/${ vendorId }/non-clients`) as Array<User>;
}

export const getUser = async function (userId: number) {
	interface User {
		address: string;
		country: string;
		email: string;
		fax: string;
		phone: string;
		bill: string;
		comment: string;
	}
	return await api.call(`${ userId }`) as User;
}

export const getUserRoles = async function (id: number) {
	interface Role {
		id: number;
		name: string;
		description: string;
		default: boolean;
		selected: boolean;
	}
	return await api.call(`${ id }/roles`) as Array<Role>;
}

export const setUserRole = async function (userId: number, roleId: number) {
	return await api.call(`${ userId }/role`, Method.Post, { roleId }) as {};
}

export const setUserField = async function (
	userId: number,
	field: keyof Response<typeof getUser>,
	value: string,
) {
	return await api.call(`${ userId }/set-${ field }`, Method.Post, {
		[field]: value,
	}) as {};
}

export const makeClient = async function (userId: number) {
	return await api.call(`${ userId }/make-client`, Method.Post) as {};
}

export const unmakeClient = async function (userId: number) {
	return await api.call(`${ userId }/unmake-client`, Method.Post) as {};
}

export const requestClient = async function (userId: number) {
	return await api.call(`${ userId }/request-client`, Method.Post) as {};
}

export const isRequested = async function (userId: number) {
	return await api.call(`${ userId }/is-requested`) as boolean;
}

export const resetPassword = async function (userId: number) {
	return await api.call(`${ userId }/reset-password`, Method.Post) as string;
}
