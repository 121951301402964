import { faHome } from "@fortawesome/free-solid-svg-icons";
import { getName } from "api/vendor";
import { SectionManager } from "components/section/utils";
import { useEffect, useState } from "react";

import styles from "./index.module.scss";

const Content = function () {
	const [username, setUsername] = useState<string>();
	
	useEffect(() => {
		(async () => {
			setUsername(await getName());
		})()
		.catch(console.error);
	}, []);

	return <div className={ styles.container }>
		<div className={ styles.card }>
			{
				username === undefined ?
				"" :
				`Welcome ${ username }!`
			}
		</div>
	</div>;
}

export const home = new SectionManager({
	name: "Home",
	icon: faHome,
	content: <Content />,
});
