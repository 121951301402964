import { useEffect, useState } from "react";
import { Loading } from "components/ui/loading";
import { Response } from "api";
import { getNonClients, getUser, setUserField } from "api/user";
import { Input } from "components/ui/input";
import { Actions } from "./actions";

import styles from "./index.module.scss";

type TUser = Response<typeof getNonClients>[number];
type DetailledUser = Response<typeof getUser>;
type Field = keyof DetailledUser;

interface Props {
	user: TUser;
	onNeedUpdate: (unselect?: boolean) => void;
}

export function User (props: Props) {
	const [dUser, setDUser] = useState<DetailledUser>();
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		(async () => {
			setLoading(true);
			setDUser(await getUser(props.user.id));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.user.id]);
	if (loading || dUser === undefined)
		return <Loading />;
	return <div>
		<div className={ styles.fields }>
			<h2>User infos</h2>
			<div>
			{
				([
					"address",
					"country",
					"email",
					"fax",
					"phone",
					"bill",
					"comment",
				] as Array<Field>)
				.map((field, index) => <div
					className={ styles.input }
					key={ index }
				>
					<b>{ field[0].toUpperCase() + field.slice(1) }:</b>
					<Input
						default={ dUser[field] }
						placeholder={ `Revendor ${ field }` }
						save={ async value => {
							await setUserField(props.user.id, field, value);
						} }
					/>
				</div>)
			}
			</div>
		</div>
		<div className={ styles.container }>
			<h2>Actions</h2>
			<Actions
				userId={ props.user.id }
				onNeedUpdate={ props.onNeedUpdate }
			/>
		</div>
	</div>;
}
