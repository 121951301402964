import { IconProp } from "@fortawesome/fontawesome-svg-core";

type Component = JSX.Element;

interface SectionConfig {
	name: string;
	content: Component;
	admin?: boolean;
	icon: IconProp;
}

export class SectionManager {
	private readonly config: SectionConfig;
	public constructor (config: SectionConfig) {
		this.config = config;
	}
	public render (): Component {
		return this.config.content;
	}
	public getConfig (): SectionConfig {
		return this.config;
	}
}
