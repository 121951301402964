import styles from "./index.module.scss";

export enum ButtonType {
	Normal,
	Danger,
	Success,
	Warning,
}

interface Props {
	name: string;
	onClick: () => void;
	type?: ButtonType;
	disabled?: boolean;
}

export const Button = function (props: Props) {
	const getType = function () {
		return props.type ?? ButtonType.Normal;
	}
	const getCustom = function (type: ButtonType) {
		return (getType() === type).toString();
	}
	// eslint-disable-next-line
	return <a
		tabIndex={ 0 }
		className={ styles.container }
		target="_blank"
		rel="noopener noreferrer"
		custom-danger={ getCustom(ButtonType.Danger) }
		custom-warning={ getCustom(ButtonType.Warning) }
		custom-success={ getCustom(ButtonType.Success) }
		custom-disabled={ (props.disabled === true).toString() }
		onClick={() => {
			if (props.disabled === true)
				return;
			props.onClick();
		}}
	>
		{ props.name }
	</a>;
}
