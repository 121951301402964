import { faBug } from "@fortawesome/free-solid-svg-icons";
import { Response } from "api";
import { getCrashes } from "api/crashes";
import { SectionManager } from "components/section/utils";
import { Loading } from "components/ui/loading";
import { useEffect } from "react";
import { useState } from "react";

import styles from "./index.module.scss";

type Crashes = Response<typeof getCrashes>;

const Content = function () {
	const [loading, setLoading] = useState(true);
	const [crashes, setCrashes] = useState<Crashes>([]);
	useEffect(() => {
		(async () => {
			setLoading(true);
			setCrashes(await getCrashes())
			setLoading(false);
		})()
		.catch(console.error);
	}, []);
	if (loading)
		return <Loading />;
	return <div className={ styles.container }>
		{
			crashes.map((crash, id) => <div
				className={ styles.crash }
				key={ id }
			>
				<p className={ styles.date }>{ crash.createdAt }</p>
				<p className={ styles.browser }>{ crash.browser }</p>
				<p className={ styles.os }>{ crash.os }</p>
				<p className={ styles.web }>{ crash.isWebsite ? "Website" : "Electron" }</p>
				<p className={ styles.message }>{ crash.message }</p>
			</div>)
		}
	</div>;
};

export const bugs = new SectionManager({
	name: "Bugs",
	icon: faBug,
	content: <Content />,
	admin: true,
});
