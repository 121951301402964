import { Button, ButtonType } from "components/ui/button";
import { deleteRole, makeDefault } from "api/role";
import { useState } from "react";
import { useMemo } from "react";

import styles from "./index.module.scss";
import { areYouSure } from "components/alert";

interface Props {
	roleId: number;
	default: boolean;
	onDelete: () => void;
}

export const Actions = function (props: Props) {
	const [madeDefault, setMadeDefault] = useState(false);
	const isDefault = useMemo(() => {
		return props.default || madeDefault;
	}, [props.default, madeDefault]);
	return <div className={ styles.container }>
		<Button
			name="Make default"
			onClick={ () => {
				(async () => {
					const sure = await areYouSure(`Are you sure you want to make this role the default role?`);
					if (!sure)
						return;
					await makeDefault(props.roleId);
					setMadeDefault(true);
				})()
				.catch(console.error);
			} }
			disabled={ isDefault }
		/>
		<Button
			name="Delete"
			type={ ButtonType.Danger }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure(`Are you sure you want to delete this role? If clients have this role, they will now have the default one`);
					if (!sure)
						return;
					await deleteRole(props.roleId);
					props.onDelete();
				})()
				.catch(console.error);
			} }
			disabled={ isDefault }
		/>
	</div>;
}
