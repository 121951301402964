import { useEffect, useMemo, useState } from "react";
import { List } from "components/ui/list";
import { faUserTie } from "@fortawesome/free-solid-svg-icons";
import { SectionManager } from "components/section/utils";
import { Client } from "./client";
import { Response } from "api";
import { getClients } from "api/user";
import { useUpdate } from "utils/hooks";
import { getVendors, isAdmin } from "api/vendor";
import { Loading } from "components/ui/loading";
import { Tabs } from "components/ui/tabs";

type Clients = Response<typeof getClients>;
type Vendors = Response<typeof getVendors>;

const Inner = function (props: {
	loading: boolean;
	vendor?: Vendors[number];
	admin: boolean;
}) {
	const [clients, setClients] = useState<Clients>([]);
	const [clientIndex, setClientIndex] = useState<number>();
	const [onUpdate, update] = useUpdate();

	const selectedClient = useMemo(() => {
		if (clientIndex === undefined)
			return undefined;
		return clients[clientIndex];
	}, [clients, clientIndex]);

	useEffect(() => {
		(async () => {
			setClients([]);
			setClientIndex(undefined);
			if (props.admin) {
				if (props.vendor === undefined)
					setClients([]);
				else
					setClients(await getClients(props.vendor.id));
			} else {
				setClients(await getClients());
			}
		})()
		.catch(console.error);
	}, [onUpdate, props.vendor, props.admin]);

	return <List
		loading={ props.loading }
		items={ clients.map(client => client.username) }
		selected={ clientIndex }
		onChange={ setClientIndex }
	>
	{
		selectedClient === undefined ? <div /> :
		<Client
			user={ selectedClient }
			onNeedUpdate={ unselect => {
				update();
				if (unselect)
					setClientIndex(-1);
			} }
		/>
	}
	</List>;
}

const Content = function () {
	const [admin, setAdmin] = useState<boolean>();
	const [vendors, setVendors] = useState<Vendors>([]);
	const [vendorIndex, setVendorIndex] = useState<number>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => setAdmin(await isAdmin()))()
		.catch(console.error);
	}, []);

	useEffect(() => {
		(async () => {
			if (admin === undefined)
				return;
			if (admin)
				setVendors(await getVendors());
			setLoading(false);
		})()
		.catch(console.error);
	}, [admin]);

	useEffect(() => {
		for (let index = 0; index < vendors.length; ++index) {
			if (!vendors[index].isAdmin)
				continue;
			setVendorIndex(index);
			break;
		}
	}, [vendors]);

	const selectedVendor = useMemo(() => {
		if (vendorIndex === undefined)
			return undefined;
		return vendors[vendorIndex];
	}, [vendors, vendorIndex]);

	if (admin === undefined)
		return <Loading />;
	if (!admin)
		return <Inner
			loading={ loading }
			admin={ admin }
		/>;
	return <Tabs
		items={ vendors.map(vendor => vendor.name) }
		selected={ vendorIndex }
		onChange={ setVendorIndex }
		loading={ vendors.length === 0 }
	>
		{ <Inner
			loading={ loading }
			vendor={ selectedVendor }
			admin={ admin }
		/> }
	</Tabs>;
};

export const clients = new SectionManager({
	name: "Clients",
	icon: faUserTie,
	content: <Content />,
});
