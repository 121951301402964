import { Api, Method } from "api";

const api = new Api("auth/vendor");

export const logIn = async function (name: string, password: string) {
	return await api.call("log-in", Method.Post, { name, password }) as {};
}

export const logOut = async function () {
	return await api.call("log-out", Method.Post) as {};
}

export const isLoggedIn = async () => {
	return await api.call("is-logged-in") as boolean;
}

export const isAdmin = async () => {
	return await api.call("is-admin") as boolean;
}

export const getName = async () => {
	return await api.call("name") as string;
}

export const getKey = async () => {
	return await api.call("key") as string;
}

export const getSoftwareName = async () => {
	return await api.call("software-name") as string;
}

export const setSoftwareName = async (softwareName: string) => {
	return await api.call("software-name", Method.Post, { softwareName });
}

export const getVendors = async () => {
	interface Vendor {
		id: number;
		name: string;
		isAdmin: boolean;
	}
	return await api.call("all") as Array<Vendor>;
}
