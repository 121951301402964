import { Response } from "api";
import {
	getRevendor,
	getRevendorRoles,
	setRevendorRoleAllowed,
	setRevendorField,
	getRequests,
} from "api/revendor";
import { Loading } from "components/ui/loading";
import { Input } from "components/ui/input";
import { useEffect } from "react";
import { useState } from "react";
import { useUpdate } from "utils/hooks";
import { Actions } from "./actions";

import styles from "./index.module.scss";
import { Button } from "components/ui/button";
import { makeClient } from "api/user";
import { areYouSure } from "components/alert";

type TRevendor = Response<typeof getRevendor>;
type Roles = Response<typeof getRevendorRoles>;
type Field = keyof TRevendor;
type Requests = Response<typeof getRequests>;

interface Props {
	revendorId: number;
	onNeedUpdate: (unselect?: boolean) => void;
}

export const Revendor = function (props: Props) {
	const [roles, setRoles] = useState<Roles>([]);
	const [requests, setRequests] = useState<Requests>([]);
	const [revendor, setRevendor] = useState<TRevendor>();
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();
	useEffect(() => {
		(async () => {
			setLoading(true);
			setRoles(await getRevendorRoles(props.revendorId));
			setRequests(await getRequests(props.revendorId));
			setRevendor(await getRevendor(props.revendorId));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.revendorId, onUpdate]);
	if (loading || revendor === undefined)
		return <Loading />;
	return <div>
		<div className={ styles.fields }>
			<h2>Revendor infos</h2>
			<div>
			{
				([
					"name",
					"password",
					"address",
					"country",
					"email",
					"fax",
					"phone",
					"bill",
					"comment",
				] as Array<Field>)
				.map((field, index) => <div
					className={ styles.input }
					key={ index }
				>
					<b>{ field[0].toUpperCase() + field.slice(1) }:</b>
					<Input
						default={ revendor[field] }
						placeholder={ `Revendor ${ field }` }
						save={ async value => {
							await setRevendorField(props.revendorId, field, value);
							props.onNeedUpdate();
						} }
					/>
				</div>)
			}
			</div>
		</div>
		<div className={ styles.container }>
			<h2>Allowed roles</h2>
			<div>
			{
				[...roles].sort((a, b) => a.id - b.id).map((role, i) => <div
					key={ i }
					className={ styles.role }
				>
					<input
					type="checkbox"
					defaultChecked={ role.allowed }
					onChange={ e => {
						(async () => {
							setLoading(true);
							const next = e.target.checked;
							await setRevendorRoleAllowed(
								props.revendorId,
								role.id,
								next,
							);
							update();
						})()
						.catch(console.error);
					} } />
					<div className={ styles.info }>
						<div className={ styles.title }>
							{ role.name }
							{ role.default ? " (default)" : "" }
						</div>
						<div className={ styles.description }>{ role.description }</div>
					</div>
				</div>)
			}
			</div>
		</div>
		<div className={ styles.container }>
			<h2>Requests</h2>
			<div>
				{
					requests.map((request, index) => <div
						key={ index }
					>
						<Button
							name="Accept"
							onClick={ () => {
								(async () => {
									const sure = await areYouSure(`Are you sure you want to make this user a client?`);
									if (!sure)
										return;
									await makeClient(request.id);
									update();
								})()
								.catch(console.error)
							} }
						/>
						{ request.name }
					</div>)
				}
				{
					requests.length === 0
					? "No request"
					: undefined
				}
			</div>
		</div>
		<Actions
			revendorId={ props.revendorId }
			onDelete={ () => props.onNeedUpdate(true) }
		/>
	</div>;
}
