import { faCog } from "@fortawesome/free-solid-svg-icons";
import { getSoftwareName, setSoftwareName as setSoftwareNameAPI } from "api/vendor";
import { SectionManager } from "components/section/utils";
import { Input } from "components/ui/input";
import { Loading } from "components/ui/loading";
import { useEffect, useState } from "react";

import styles from "./index.module.scss";

const Content = function () {
	const [loading, setLoading] = useState(true);
	const [softwareName, setSoftwareName] = useState<string>();
	useEffect(() => {
		(async () => {
			setLoading(true);
			setSoftwareName(await getSoftwareName());
			setLoading(false);
		})()
		.catch(console.error);
	}, []);

	if (loading || softwareName === undefined)
		return <Loading />;
	return <div className={ styles.container }>
		<div className={ styles.input }>
			<b>Software name:</b>
			<Input
				default={ softwareName }
				save={ async value => await setSoftwareNameAPI(value) }
				placeholder="Label Designer 4"
			/>
		</div>
	</div>;
};

export const settings = new SectionManager({
	name: "Settings",
	icon: faCog,
	content: <Content />,
});
