import { Loading } from "../loading";
import styles from "./index.module.scss";

interface Props {
	items: Array<string>;
	selected?: number;
	onChange?: (index: number) => void;
	loading?: boolean;
	children: React.ReactChild;
}

export const Tabs = function (props: Props) {
	if (props.loading === true)
		return <Loading />;
	return <div className={ styles.container }>
		<div className={ styles.tabs }>
			{ props.items.map((item, i) => <div
				custom-selected={ (i === props.selected).toString() }
				key={ i }
				onClick={ () => {
					if (i === props.selected)
						return;
					props.onChange?.(i)
				} }
			>
				{ item }
			</div>) }
		</div>
		{ props.children }
	</div>;
}
