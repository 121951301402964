import Login from "../login";
import Main from "../main";
import { ToastContainer } from "react-toastify";
import { useEffect, useState } from "react";
import { isLoggedIn } from "api/vendor";
import { Loading } from "components/ui/loading";

import "./index.scss";
import "react-toastify/dist/ReactToastify.css";

export default function App () {
	const [loading, setLoading] = useState(true);
	const [loggedIn, setLoggedIn] = useState(false);
	useEffect(() => {
		(async () => {
			setLoggedIn(await isLoggedIn());
			setLoading(false);
		})()
		.catch(console.error);
	}, []);
	if (loading)
		return <Loading />;
	return <>
		{ loggedIn ? <Main /> : <Login /> }
		<ToastContainer />
	</>;
}
