import { deleteLanguage, isLanguageInSoftware, setLanguageInSoftware } from "api/translation";
import { areYouSure } from "components/alert";
import { Button, ButtonType } from "components/ui/button";
import { Loading } from "components/ui/loading";
import { useEffect, useState } from "react";

import styles from "./index.module.scss";

interface Props {
	languageId: number;
	onDelete: () => void;
}

export const Actions = function (props: Props) {
	const [inSoftware, setInSoftware] = useState(false);
	const [loading, setLoading] = useState(true);
	useEffect(() => {
		(async () => {
			setLoading(true);
			setInSoftware(await isLanguageInSoftware(props.languageId));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.languageId]);
	if (loading)
		return <Loading />;
	return <div className={ styles.container }>
		<Button
			name={ inSoftware ? "Hide in software" : "Show in software" }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure(`Are you sure you want to ${ inSoftware ? "hide" : "show" } this language?`);
					if (!sure)
						return;
					await setLanguageInSoftware(props.languageId, !inSoftware);
					setInSoftware(!inSoftware);
				})()
				.catch(console.error);
			} }
		/>
		<Button
			name="Delete"
			type={ ButtonType.Danger }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure(`Are you sure you want to delete this language?`);
					if (!sure)
						return;
					await deleteLanguage(props.languageId);
					props.onDelete();
				})()
				.catch(console.error);
			} }
		/>
	</div>;
}
