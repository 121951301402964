const API_SERVER = "https://api.server.printack.eu";

const HTTP_OK = 200;
const HTTP_INTERNAL = 500;

export enum Method {
	Get = "get",
	Post = "post",
	Put = "put",
	Patch = "patch",
	Delete = "delete",
}

interface AjaxResponse {
	status: number;
	error?: Error;
	data: any;
}

export class Api {
	private readonly baseAPI: string;
	public constructor (baseAPI: string = "") {
		this.baseAPI = baseAPI;
	}
	private async ajax (url: string, method: Method, dataParams?: any): Promise<AjaxResponse> {
		try {
			const response = await fetch(url, {
				mode: "cors",
				credentials: "include",
				method,
				headers: {
					"Content-Type": "application/json",
				},
				body: dataParams === undefined ? undefined : JSON.stringify({ data: dataParams }),
			});
			const { status } = response;
			const data = await response.json();
			return {
				status,
				data: status === HTTP_OK ? data : {},
				error: status === HTTP_OK ? undefined : new Error(data),
			};
		} catch (_) {
			return { status: HTTP_INTERNAL, data: {}, error: new Error("Communication with the server failed!") };
		}
	}
	public async call (api: string, method: Method = Method.Get, dataParams?: any): Promise<any> {
		const { data, status, error } = await this.ajax(`${ API_SERVER }/${ this.baseAPI }/${ api }`, method, dataParams);
		if (status !== HTTP_OK)
			throw error;
		return data;
	}
}

type Then<T> = T extends PromiseLike<infer U> ? U : T;
export type Response<T extends (...a: Array<any>) => Promise<any>> = Then<ReturnType<T>>;
