import { isRequested, makeClient, requestClient, resetPassword } from "api/user";
import { isAdmin } from "api/vendor";
import { areYouSure } from "components/alert";
import { Button, ButtonType } from "components/ui/button";
import { Loading } from "components/ui/loading";
import { useEffect } from "react";
import { useState } from "react";
import { useUpdate } from "utils/hooks";

import styles from "./index.module.scss";

interface Props {
	userId: number;
	onNeedUpdate: (unselect: boolean) => void;
}

export const Actions = function (props: Props) {
	const [admin, setAdmin] = useState(false);
	const [requested, setRequested] = useState(false);
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();
	useEffect(() => {
		(async () => {
			setLoading(true);
			setAdmin(await isAdmin());
			setRequested(await isRequested(props.userId));
			setLoading(false);
		})()
		.catch(console.error);
	}, [onUpdate, props.userId])
	if (loading)
		return <Loading black />;
	return <div className={ styles.container }>
		{
			admin
			? <Button
				name="Make client"
				onClick={ () => {
					(async () => {
						await makeClient(props.userId);
						update();
						props.onNeedUpdate(true);
					})()
					.catch(console.error);
				} }
			/>
			: <Button
				name="Request to make client"
				onClick={ () => {
					(async () => {
						await requestClient(props.userId);
						update();
						props.onNeedUpdate(false);
					})()
					.catch(console.error);
				} }
				disabled={ requested }
			/>
		}
		<Button
			name="Reset user password"
			type={ ButtonType.Warning }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure("Are you sure you want to reset this user's password?");
					if (!sure)
						return;
					const newPassword = await resetPassword(props.userId);
					alert(`The new password is "${ newPassword }" (without quotes).`);
				})()
				.catch(console.error);
			} }
		/>
	</div>;
}
