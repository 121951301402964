import { faShare } from "@fortawesome/free-solid-svg-icons";
import { getKey } from "api/vendor";
import { SectionManager } from "components/section/utils";
import { useEffect, useState } from "react";

import styles from "./index.module.scss";

const Content = function () {
	const [key, setKey] = useState("");

	useEffect(() => {
		(async () => {
			setKey(await getKey())
		})()
		.catch(console.error);
	}, []);

	const download = "https://api.server.printack.eu/download";
	return <div className={ styles.container }>
		<div>
			<b>Download:</b> { download }
		</div>
		<div>
			<b>Key:</b> { key }
		</div>
	</div>;
}

export const distribute = new SectionManager({
	name: "Distribute",
	icon: faShare,
	content: <Content />,
});
