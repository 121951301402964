import { faRetweet } from "@fortawesome/free-solid-svg-icons";
import { SectionManager } from "components/section/utils";
import { useEffect, useMemo, useState } from "react";
import { List } from "components/ui/list";
import { Response } from "api";
import { getRevendors, newRevendor } from "api/revendor";
import { Revendor } from "./revendor";
import { useUpdate } from "utils/hooks";

type Revendors = Response<typeof getRevendors>;

const Content = function () {
	const [revendors, setRevendors] = useState<Revendors>([]);
	const [selected, setSelected] = useState<number | undefined>(undefined);
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();

	const selectedRevendor = useMemo(() => {
		if (revendors === undefined || selected === undefined)
			return undefined;
		return revendors[selected];
	}, [revendors, selected]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			setRevendors(await getRevendors());
			setLoading(false);
		})()
		.catch(console.error);
	}, [onUpdate]);
	return <List
		loading={ loading }
		items={ revendors.map(revendor => revendor.name) }
		selected={ selected }
		onChange={ setSelected }
		onAdd={ name => {
			(async () => {
				setLoading(true);
				await newRevendor(name);
				update();
			})()
			.catch(console.error);
		} }
	>
	{
		selectedRevendor === undefined ? <div /> :
		<Revendor
			revendorId={ selectedRevendor.id }
			onNeedUpdate={ unselect => {
				update();
				if (unselect)
					setSelected(-1);
			} }
		/>
	}
	</List>;
};

export const revendors = new SectionManager({
	name: "Revendors",
	icon: faRetweet,
	content: <Content />,
	admin: true,
});
