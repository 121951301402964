import { useEffect, useState } from "react";
import { Response } from "api";
import { getRole, getRightCategories, setDescription, setName } from "api/role";
import { Loading } from "components/ui/loading";
import { CategoryList } from "./category-list";
import { Input } from "components/ui/input";
import { Actions } from "./actions";

import styles from "./index.module.scss";

type Role = Response<typeof getRole>;
type RightCategories = Response<typeof getRightCategories>;

interface Props {
	roleId: number;
	onNeedUpdate: (unselect?: boolean) => void;
}

export default function RightsEditor(props: Props) {
	const [loading, setLoading] = useState(true);
	const [categories, setCategories] = useState<RightCategories>([]);
	const [role, setRole] = useState<Role>();

	useEffect(() => {
		(async () => {
			setLoading(true);
			setCategories(await getRightCategories());
			setRole(await getRole(props.roleId));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.roleId]);

	if (loading || role === undefined)
		return <Loading />;
	return <div className={ styles.container }>
		<div className={ styles.input }>
			<b>Name:</b>
			<Input
				default={ role.name }
				placeholder="Role name"
				save={ async name => {
					await setName(props.roleId, name);
					props.onNeedUpdate();
				} }
			/>
		</div>
		<div className={ styles.input }>
			<b>Description:</b>
			<Input
				default={ role.description }
				placeholder="Role description"
				save={async desc => {
					await setDescription(props.roleId, desc);
				} }
			/>
		</div>
		<Actions
			roleId={ props.roleId }
			default={ role.default }
			onDelete={ () => props.onNeedUpdate(true) }
		/>
		{
			categories.map((category, i) => <div key={ i } className={ styles.category }>
				<h2>{ category.name }</h2>
				<CategoryList roleId={ props.roleId } categoryId={ category.id } />
			</div>)
		}
	</div>;
}
