import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { compareTwoStrings } from "string-similarity";
import { Loading } from "components/ui/loading";
import { Button } from "components/ui/button";

import styles from "./index.module.scss";

interface Item {
	index: number;
	value: string;
}

interface Props {
	items: string[];
	selected?: number;
	onChange?: (index: number) => void;
	loading?: boolean;
	onAdd?: (element: string) => void;
}

export const VerticalBar = function (props: Props) {
	const items: Item[] = useMemo(
		() => props.items.map((value, index) => ({ value, index })),
		[props.items],
	);

	const [searchText, setSearchText] = useState("");
	const [newText, setNewText] = useState("");
	const [sortedItems, setSortedItems] = useState(items);

	useEffect(() => {
		setSortedItems(
			searchText.length === 0
			? [...items]
			: [...items].sort((a, b) => {
				const compA = compareTwoStrings(a.value, searchText);
				const compB = compareTwoStrings(b.value, searchText);
				return compB - compA;
			}),
		);
	}, [items, searchText]);

	const onItemClick = (index: number) => {
		if (props.onChange !== undefined)
			props.onChange(index);
	};

	return <div className={ styles.container }>
		<div
			className={ styles.searchBar }
			custom-text-inside={ (searchText.length > 0).toString() }
		>
			<input
				type="text"
				placeholder="Search..."
				value={ searchText }
				onChange={ event => setSearchText(event.currentTarget.value) }
			/>
			{
				searchText.length === 0
				? undefined
				: <FontAwesomeIcon
					className={ styles.clearIcon }
					icon={ faTimes }
					onClick={ () => setSearchText("") }
				/>
			}
		</div>
		{
			props.loading
			? <Loading />
			: <div className={ styles.content }>
				{
					sortedItems.length === 0
					? <p className={ styles.message }>List is empty</p>
					: sortedItems.map(item => <div
						key={ item.index }
						className={ styles.item }
						custom-selected={ (props.selected === item.index).toString() }
						onClick={ () => onItemClick(item.index) }
					>
						{ item.value }
					</div>)
				}
			</div>
		}
		{
			props.onAdd === undefined
			? <></>
			: <div
				className={ styles.new }
				custom-text-inside={ (searchText.length > 0).toString() }
			>
				<input
					type="text"
					placeholder="New..."
					value={ newText }
					onChange={ event => setNewText(event.currentTarget.value) }
				/>
				<Button
					name="Add"
					disabled={ newText.length === 0 }
					onClick={ () => {
						if (props.loading)
							return;
						props.onAdd?.(newText);
						setNewText("");
					} }
				/>
			</div>
		}
	</div>;
}
