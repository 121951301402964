import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import { SectionManager } from "components/section/utils";
import { List } from "components/ui/list";
import { useMemo, useState } from "react";
import { Response } from "api";
import { getLanguages, newLanguage } from "api/translation";
import { useEffect } from "react";
import { Phrases } from "./phrases";
import { useUpdate } from "utils/hooks";

type Languages = Response<typeof getLanguages>;

const Content = function () {
	const [languages, setLanguages] = useState<Languages>([]);
	const [selected, setSelected] = useState(-1);
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();

	const selectedLanguage = useMemo(() => {
		if (languages === undefined || selected === -1)
			return undefined;
		return languages[selected];
	}, [languages, selected]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			setLanguages(await getLanguages());
			setLoading(false);
		})()
		.catch(console.error);
	}, [onUpdate]);
	
	return <List
		loading={ loading }
		items={ languages.map(l => l.name) }
		selected={ selected }
		onChange={ setSelected }
		onAdd={ name => {
			(async () => {
				setLoading(true);
				await newLanguage(name);
				update();
			})()
			.catch(console.error);
		} }
	>
	{
		selectedLanguage === undefined ? <div /> :
		<Phrases
			language={ selectedLanguage }
			onNeedUpdate={ unselect => {
				update();
				if (unselect)
					setSelected(-1);
			} }
		/>
	}
	</List>;
}

export const translations = new SectionManager({
	name: "Translations",
	icon: faLanguage,
	content: <Content />,
	admin: true,
});
