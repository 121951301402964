import { Response } from "api";
import { Loading } from "components/ui/loading";
import { getLanguages, getPhrases, setLanguageName, translate } from "api/translation";
import { useState, useEffect } from "react";
import { Input } from "components/ui/input";
import { Actions } from "./actions";

import styles from "./index.module.scss";

type Language = Response<typeof getLanguages>[number];
type TPhrases = Response<typeof getPhrases>;

interface Props {
	language: Language;
	onNeedUpdate: (unselect?: boolean) => void;
}

export const Phrases = function (props: Props) {
	const [phrases, setPhrases] = useState<TPhrases>([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => {
			setLoading(true);
			setPhrases(await getPhrases(props.language.id));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.language]);

	if (loading)
		return <Loading />;
	return <div className={ styles.container }>
		<div className={ styles.input }>
			Name: <Input
				default={ props.language.name }
				placeholder="Language name"
				save={ async name => {
					await setLanguageName(props.language.id, name);
					props.onNeedUpdate();
				} }
			/>
		</div>
		<Actions
			languageId={ props.language.id }
			onDelete={ () => props.onNeedUpdate(true) }
		/>
		{
			phrases.map((phrase, index) => <div key={ index } className={ styles.phrase }>
				<div>{ phrase.english }</div>
				<div>
					<Input
						default={ phrase.translation ?? "" }
						save={ async value => {
							await translate(props.language.id, phrase.id, value);
						} }
					/>
				</div>
			</div>)
		}
	</div>;
}
