import { resetPassword, unmakeClient } from "api/user";
import { areYouSure } from "components/alert";
import { Button, ButtonType } from "components/ui/button";

import styles from "./index.module.scss";

interface Props {
	userId: number;
	onNeedUpdate: (unselect: boolean) => void;
}

export const Actions = function (props: Props) {
	return <div className={ styles.container }>
		<Button
			name="Reset user password"
			type={ ButtonType.Warning }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure("Are you sure you want to reset this user's password?");
					if (!sure)
						return;
					const newPassword = await resetPassword(props.userId);
					alert(`The new password is "${ newPassword }" (without quotes).`);
				})()
				.catch(console.error);
			} }
		/>
		<Button
			name="Remove client"
			type={ ButtonType.Danger }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure("Are you sure you want to remove this client?");
					if (!sure)
						return;
					await unmakeClient(props.userId);
					props.onNeedUpdate(true);
				})()
				.catch(console.error);
			} }
		/>
	</div>;
}
