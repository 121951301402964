import { Button, ButtonType } from "components/ui/button";
import { deleteRevendor } from "api/revendor";

import styles from "./index.module.scss";
import { areYouSure } from "components/alert";

interface Props {
	revendorId: number;
	onDelete: () => void;
}

export const Actions = function (props: Props) {
	return <div className={ styles.container }>
		<Button
			name="Delete"
			type={ ButtonType.Danger }
			onClick={ () => {
				(async () => {
					const sure = await areYouSure(`Are you sure you want to delete this revendor?`);
					if (!sure)
						return;
					await deleteRevendor(props.revendorId);
					props.onDelete();
				})()
				.catch(console.error);
			} }
		/>
	</div>;
}
