import { useState } from "react";
import { Response } from "api";
import { getRights, setRightEnabled } from "api/role";
import { useEffect } from "react";
import { Loading } from "components/ui/loading";

import styles from "./index.module.scss";

type Rights = Response<typeof getRights>;

interface Props {
	roleId: number;
	categoryId: number;
}

export const CategoryList = function (props: Props) {
	const [loading, setLoading] = useState(true);
	const [rights, setRights] = useState<Rights>([]);
	useEffect(() => {
		(async () => {
			setLoading(true);
			setRights(await getRights(props.roleId, props.categoryId));
			setLoading(false);
		})()
		.catch(console.error);
	}, [props.roleId, props.categoryId]);
	if (loading)
		return <Loading black />;
	return <ul className={ styles.list }>
		{ rights.map((right, i) => <li key={ i }>
			<input
				type="checkbox"
				defaultChecked={ right.enabled }
				onChange={ e => {
					(async () => {
						// setLoading(true);
						const next = e.target.checked;
						await setRightEnabled(props.roleId, right.id, next);
						// setRights(await getRights(props.roleId, props.categoryId));
						// setLoading(false);
					})()
					.catch(console.error);
				} } />
			{ right.name }
		</li>) }
	</ul>;
}
