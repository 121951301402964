import ButtonIcon from "../ui/button-icon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isAdmin, logOut } from "api/vendor";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { sections } from "components/section";
import { Loading } from "components/ui/loading";

import styles from "./index.module.scss";

interface Props {
	selectedIndex: number;
	onChange: (newSelectedIndex: number) => void;
}

export default function TopBar(props: Props) {
	const [disconnecting, setDisconnecting] = useState(false);
	const [admin, setAdmin] = useState(false);
	const [loading, setLoading] = useState(true);

	const onLogout = useCallback(() => setDisconnecting(true), []);

	useEffect(() => {
		(async () => {
			setAdmin(await isAdmin());
			setLoading(false);
		})()
		.catch(console.error);
	}, []);

	useEffect(() => {
		(async () => {
			try {
				if (!disconnecting)
					return;
				await logOut();
				window.location.reload();
			} catch {
				toast("The server did not respond", { type: "error" });
			}
			setDisconnecting(false);
		})()
		.catch(console.error);
	}, [disconnecting]);

	if (loading)
		return <Loading />;
	return <div className={ styles.container }>
		<div className={ styles.buttons }>
		{
			sections
			.map((section, index ) => ({ section, index }))
			.filter(({ section }) => {
				const adminSection = section.getConfig().admin === true;
				const shouldAppear = admin || !adminSection;
				return shouldAppear;
			})
			.map(({ section, index }) => <ButtonIcon
				icon={ <FontAwesomeIcon icon={ section.getConfig().icon } /> }
				label={ section.getConfig().name }
				action={ () => props.onChange(index) }
				selected={ props.selectedIndex === index }
				key={ index }
			/>)
		}
		</div>
		<div className={ styles.buttons }>
			<ButtonIcon
				icon={ <FontAwesomeIcon icon={ faSignOutAlt } /> }
				label="Logout"
				action={ onLogout }
				loading={ disconnecting }
				selected={ false }
			/>
		</div>
	</div>;
};