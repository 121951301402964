import { useState } from "react";

import styles from "./index.module.scss";

interface Props {
	default: string;
	save: (value: string) => Promise<void>;
	placeholder?: string;
}

enum Color {
	None = "white",
	OK = "#c8ffc8",
	Warning = "#ffdea3",
	Error = "#ffa3a3",
}

export const Input = function (props: Props) {
	const [value, setValue] = useState(props.default);
	const [color, setColor] = useState(Color.None);
	return <input
		className={ styles.container }
		style={{
			backgroundColor: color,
		}}
		placeholder={ props.placeholder ?? props.default }
		value={ value }
		onChange={ e => {
			setValue(e.target.value);
			setColor(Color.Warning);
		} }
		custom-color={ color }
		onKeyPress={ e => {
			if (e.key !== "Enter")
				return;
			props.save(value)
			.then(() => setColor(Color.OK))
			.catch(err => {
				console.error(err);
				setColor(Color.Error);
			});
		} }
	/>;
}
