import { Api } from "api";

const api = new Api("api/crash");

export const getCrashes = async function () {
	interface Crash {
		id: number;
		message: string;
		browser: string;
		os: string;
		isWebsite: boolean;
		createdAt: Date;
	}
	return await api.call("/all") as Array<Crash>;
}
