import { VerticalBar } from "components/ui/list/vertical-bar";

import styles from "./index.module.scss";

interface Props {
	items: string[];
	selected?: number;
	onChange?: (index: number) => void;
	loading?: boolean;
	children: React.ReactChild;
	onAdd?: (element: string) => void;
}

export const List = function (props: Props) {
	return <div className={ styles.container }>
		<VerticalBar
			items={ props.items }
			selected={ props.selected }
			onChange={ props.onChange }
			loading={ props.loading }
			onAdd={ props.onAdd }
		/>
		<div className={ styles.content }>
			{ props.children }
		</div>
	</div>;
}