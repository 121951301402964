import { SectionManager } from "./utils";

import { home } from "./sections/home";
import { bugs } from "./sections/bugs";
// import { translators } from "./sections/translators";
import { translations } from "./sections/translations";
import { revendors } from "./sections/revendors";
import { roles } from "./sections/roles";
import { users } from "./sections/users";
import { clients } from "./sections/clients";
import { distribute } from "./sections/distribute";
import { settings } from "./sections/settings";

export const sections: Array<SectionManager> = [
	home,
	bugs,
	// translators,
	translations,
	revendors,
	roles,
	users,
	clients,
	distribute,
	settings,
];

export const getSection = function (index: number): SectionManager {
	if (index >= sections.length || index < 0)
		throw new Error(`No section at index ${ index }`);
	return sections[index];
}
