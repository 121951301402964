import TopBar from "../top-bar";
import { useState } from "react";
import { getSection } from "components/section";

import styles from "./index.module.scss";

export default function Main() {
	const [sectionIndex, setSectionIndex] = useState(0);
	return <div className={ styles.container }>
		<TopBar selectedIndex={ sectionIndex } onChange={ setSectionIndex }/>
		{ getSection(sectionIndex).render() }
	</div>;
}
