import { useEffect, useMemo, useState } from "react";
import { List } from "components/ui/list";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { SectionManager } from "components/section/utils";
import { User } from "./user";
import { Response } from "api";
import { getNonClients } from "api/user";
import { getVendors, isAdmin } from "api/vendor";
import { useUpdate } from "utils/hooks";
import { Tabs } from "components/ui/tabs";
import { Loading } from "components/ui/loading";

type Users = Response<typeof getNonClients>;
type Vendors = Response<typeof getVendors>;

const Inner = function (props: {
	loading: boolean;
	vendor?: Vendors[number];
	admin: boolean;
}) {
	const [users, setUsers] = useState<Users>([]);
	const [userIndex, setUserIndex] = useState<number>();
	const [onUpdate, update] = useUpdate();

	const selectedUser = useMemo(() => {
		if (userIndex === undefined)
			return undefined;
		return users[userIndex];
	}, [users, userIndex]);

	useEffect(() => {
		(async () => {
			setUsers([]);
			setUserIndex(undefined);
			if (props.admin) {
				if (props.vendor === undefined)
					setUsers([]);
				else
					setUsers(await getNonClients(props.vendor.id));
			} else {
				setUsers(await getNonClients());
			}
		})()
		.catch(console.error);
	}, [onUpdate, props.vendor, props.admin]);

	return <List
		loading={ props.loading }
		items={ users.map(user => user.username) }
		selected={ userIndex }
		onChange={ setUserIndex }
	>
	{
		selectedUser === undefined ? <div /> :
		<User
			user={ selectedUser }
			onNeedUpdate={ unselect => {
				update();
				if (unselect)
					setUserIndex(-1);
			} }
		/>
	}
	</List>;
}

const Content = function () {
	const [admin, setAdmin] = useState<boolean>();
	const [vendors, setVendors] = useState<Vendors>([]);
	const [vendorIndex, setVendorIndex] = useState<number>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		(async () => setAdmin(await isAdmin()))()
		.catch(console.error);
	}, []);

	useEffect(() => {
		(async () => {
			if (admin === undefined)
				return;
			if (admin)
				setVendors(await getVendors());
			setLoading(false);
		})()
		.catch(console.error);
	}, [admin]);

	useEffect(() => {
		for (let index = 0; index < vendors.length; ++index) {
			if (!vendors[index].isAdmin)
				continue;
			setVendorIndex(index);
			break;
		}
	}, [vendors]);

	const selectedVendor = useMemo(() => {
		if (vendorIndex === undefined)
			return undefined;
		return vendors[vendorIndex];
	}, [vendors, vendorIndex]);

	if (admin === undefined)
		return <Loading />;
	if (!admin)
		return <Inner
			loading={ loading }
			admin={ admin }
		/>;
	return <Tabs
		items={ vendors.map(vendor => vendor.name) }
		selected={ vendorIndex }
		onChange={ setVendorIndex }
		loading={ vendors.length === 0 }
	>
		{ <Inner
			loading={ loading }
			vendor={ selectedVendor }
			admin={ admin }
		/> }
	</Tabs>;
};

export const users = new SectionManager({
	name: "Users",
	icon: faUser,
	content: <Content />,
});
