import { useEffect, useMemo, useState } from "react";
import { faIdBadge } from "@fortawesome/free-solid-svg-icons";
import { SectionManager } from "components/section/utils";
import { getRoles, newRole } from "api/role";
import { Response } from "api";
import { List } from "components/ui/list";
import Editor from "./editor";
import { useUpdate } from "utils/hooks";

type Roles = Response<typeof getRoles>;

const Content = function () {
	const [roles, setRoles] = useState<Roles>([]);
	const [selected, setSelected] = useState(-1);
	const [loading, setLoading] = useState(true);
	const [onUpdate, update] = useUpdate();

	const selectedRole = useMemo(() => {
		if (roles === undefined || selected === -1)
			return undefined;
		return roles[selected];
	}, [roles, selected]);

	useEffect(() => {
		(async () => {
			setLoading(true);
			setRoles(await getRoles());
			setLoading(false);
		})()
		.catch(console.error);
	}, [onUpdate]);

	return <List
		loading={ loading }
		items={ roles.map(role => role.name) }
		selected={ selected }
		onChange={ setSelected }
		onAdd={ name => {
			(async () => {
				setLoading(true);
				await newRole(name);
				update();
			})()
			.catch(console.error);
		} }
	>
	{
		selectedRole === undefined ? <div /> :
		<Editor
			roleId={ selectedRole.id }
			onNeedUpdate={ unselect => {
				update();
				if (unselect)
					setSelected(-1);
			} }
		/>
	}
	</List>;
};

export const roles = new SectionManager({
	name: "Roles",
	icon: faIdBadge,
	content: <Content />,
	admin: true,
});
