import { Api, Method } from "./index";

const api = new Api("api/translation");

export const getLanguages = async function () {
	interface Language {
		id: number;
		name: string;
	}
	return await api.call("languages") as Array<Language>;
}

export const getPhrases = async function (languageId: number) {
	interface Phrase {
		id: number;
		english: string;
		translation?: string;
	}
	return await api.call(`phrases/${ languageId }`) as Array<Phrase>;
}

export const translate = async function (languageId: number, phraseId: number, translation: string) {
	return await api.call("translate", Method.Post, {
		languageId,
		phraseId,
		translation,
	});
}

export const newLanguage = async function (name: string) {
	return await api.call("new-language", Method.Post, { name }) as {};
}

export const deleteLanguage = async function (languageId: number) {
	return await api.call("delete-language", Method.Delete, { languageId }) as {};
}

export const setLanguageName = async function (languageId: number, newName: string) {
	return await api.call("set-language-name", Method.Post, { languageId, newName }) as {};
}

export const isLanguageInSoftware = async function (languageId: number) {
	return await api.call(`is-language-in-software/${ languageId }`) as boolean;
}

export const setLanguageInSoftware = async function (languageId: number, inSoftware: boolean) {
	return await api.call(`set-language-in-software/${ languageId }`, Method.Post, { inSoftware }) as {};
}
