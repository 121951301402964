import styles from "./index.module.scss";

interface Props {
	black?: boolean;
}

export const Loading = function (props: Props) {
	return <div className={ styles.container }>
		<div>
			<div custom-black={ (props.black === true).toString() } />
			<div custom-black={ (props.black === true).toString() } />
			<div custom-black={ (props.black === true).toString() } />
			<div custom-black={ (props.black === true).toString() } />
		</div>
	</div>;
}
