import { useCallback, useEffect, useState } from "react";
import { logIn } from "api/vendor";
import { toast } from "react-toastify";

import styles from "./index.module.scss";

export default function Login() {
	const [connecting, setConnecting] = useState(false);
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState(false);

	const onLogin = useCallback(() => {
		if (username.length === 0 || password.length === 0)
			return toast.error("Enter your credentials!");
		setConnecting(true);
	}, [password, username]);

	useEffect(() => {
		(async () => {
			try {
				if (!connecting)
					return;
				setError(false);
				await logIn(username, password);
				window.location.reload();
			} catch (err) {
				setError(true);
			}
			setConnecting(false);
		})()
		.catch(console.error);
	}, [connecting, password, username]);

	return <div className={ styles.container }>
		<div className={ styles.card }>
			<div className={ styles.title }>
				<h1>Designer 3 Manager</h1>
				{
					(connecting && <h2>Connecting...</h2>)
					|| (error && <h2>Error</h2>)
				}
			</div>
			<div className={ styles.inputs }>
				<label htmlFor="username">Username</label>
				<input
					id="username"
					type="text"
					placeholder="manager"
					value={ username }
					onChange={ event => setUsername(event.currentTarget.value) }
					tabIndex={ 1 }
					autoFocus
				/>
				<label htmlFor="password">Password</label>
				<input
					id="password"
					type="password"
					placeholder="********"
					value={ password }
					onChange={ event => setPassword(event.currentTarget.value) }
					tabIndex={ 2 }
					onKeyPress={ event => event.key === "Enter" && onLogin() }
				/>
			</div>
			<div className={ styles.bottom }>
				<div
					className={ styles.loginButton }
					tabIndex={ 3 }
					onClick={ onLogin }
				>
					Login
				</div>
			</div>
		</div>
	</div>;
};
