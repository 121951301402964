import { Api, Method, Response } from "api";

const api = new Api("api/revendor");

export const getRevendors = async function () {
	interface Revendor {
		id: number;
		name: string;
	}
	return await api.call("all") as Array<Revendor>;
}

export const getRevendor = async function (revendorId: number) {
	interface Revendor {
		name: string;
		password: string;
		address: string;
		country: string;
		email: string;
		fax: string;
		phone: string;
		bill: string;
		comment: string;
	}
	return await api.call(`${ revendorId }`) as Revendor;
}

export const getRevendorRoles = async function (revendorId: number) {
	interface Role {
		id: number;
		name: string;
		description: string;
		default: boolean;
		allowed: boolean;
	}
	return await api.call(`${ revendorId }/roles`) as Array<Role>;
}

export const getRequests = async function (revendorId: number) {
	interface User {
		id: number;
		name: string;
	}
	return await api.call(`${ revendorId }/requests`) as Array<User>;
}

export const setRevendorRoleAllowed = async function (
	revendorId: number,
	roleId: number,
	allowed: boolean,
) {
	return await api.call(`${ revendorId }/role/${ roleId }/set-allowed`, Method.Post, {
		allowed,
	}) as {};
}

export const newRevendor = async function (name: string) {
	return await api.call("new", Method.Post, { name }) as {};
}

export const deleteRevendor = async function (revendorId: number) {
	return await api.call(`${ revendorId }`, Method.Delete) as {};
}

export const setRevendorField = async function (
	revendorId: number,
	field: keyof Response<typeof getRevendor>,
	value: string,
) {
	return await api.call(`${ revendorId }/set-${ field }`, Method.Post, {
		[field]: value,
	}) as {};
}
