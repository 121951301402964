import styles from "./index.module.scss";

export interface Props {
	icon: JSX.Element;
	label: string;
	action?: () => any;
	loading?: boolean;
	selected: boolean;
}

export default function ButtonIcon(props: Props) {
	const isLoading = props.loading === true;

	const onClick = () => {
		if (!isLoading && props.action !== undefined)
			props.action();
	}

	return <div
		className={ styles.container }
		onClick={ onClick }
		custom-loading={ isLoading.toString() }
		custom-selected={ props.selected.toString() }
	>
		<div className={ styles.icon }>
			{ props.icon }
		</div>
		<div className={ styles.label }>
			{ props.label }
		</div>
	</div>;
}
