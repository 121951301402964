import { Api, Method } from "./index";

const api = new Api("api/role");

export const getRoles = async function () {
	interface Role {
		id: number;
		name: string;
	}
	return await api.call("all") as Array<Role>;
}

export const getRole = async function (roleId: number) {
	interface Role {
		name: string;
		default: boolean;
		description: string;
	}
	return await api.call(`detailled/${ roleId }`) as Role;
}

export const getRightCategories = async function () {
	interface RightCategory {
		id: number;
		name: string;
	}
	return await api.call("right-categories") as Array<RightCategory>;
}

export const getRights = async function (roleId: number, categoryId: number) {
	interface Right {
		id: number;
		name: string;
		enabled: boolean;
	}
	return await api.call(`${ roleId }/${ categoryId }/rights`) as Array<Right>;
}

export const setRightEnabled = async function (roleId: number, rightId: number, enabled: boolean) {
	return await api.call(`${ roleId }/set-right`, Method.Post, {
		rightId,
		enabled,
	}) as {};
}

export const setName = async function (roleId: number, name: string) {
	return await api.call(`${ roleId }/name`, Method.Post, { name }) as {};
}

export const setDescription = async function (roleId: number, description: string) {
	return await api.call(`${ roleId }/description`, Method.Post, { description }) as {};
}

export const newRole = async function (name: string) {
	return await api.call("/new", Method.Post, { name }) as {};
}

export const deleteRole = async function (roleId: number) {
	return await api.call(`${ roleId }`, Method.Delete) as {};
}

export const makeDefault = async function (roleId: number) {
	return await api.call(`${ roleId }/make-default`, Method.Post);
}
